import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'lib-flexible/flexible'
import Vant from 'vant';
import 'vant/lib/index.css';


URLSearchParams.prototype.appendIfExists = function (key, value) {
    if (value !== null && value !== undefined) {
        this.append(key, value)
    }
};

const app = createApp(App);
app.use(Vant);
app.use(router).use(store).mount('#app');

// new Vue({
//     router,
//     store,
//     render: h => h(App)
// }).$mount('#app')
  