<template>
  <div class="home">
    <van-cell-group>
        <van-field v-model="station_id" label="Station Id" placeholder="请输入Station ID" />
        <van-button type="primary" @click="onButtonSetStationId">Set Station ID</van-button>
        <span></span>
        <van-button type="danger" @click="onButtonGoHome">Go Home</van-button>
    </van-cell-group>

    <van-cell-group
      class=""
      v-for="(item, index) in platform_list" :key="index"
      :title="'Platform: ' + item.platform_id"
    >
        <van-cell 
        v-for="(routeItem, indexRoute) in item.route_list" :key="indexRoute"
        :title="routeItem.route_no + ' ' + routeItem.dest_ch + ' ' + routeItem.train_length" :value="routeItem.time_ch" />

    </van-cell-group>
  </div>
</template>

<script>
// @ is an alias to /src
import { getSchedule } from '../service/lightrailway'
import { Toast} from 'vant'
import { getLocal, setLocal } from '@/common/js/utils'

export default {
  name: 'StationList',
  data() {
    return {
      platform_list: [],
      address: {},
      showPay: false,
      orderNo: '',
      cartItemIds: [],
      station_id: '',
    }
  },
  mounted() {
    const station_id = getLocal('station_id')
    this.station_id = station_id
    this.init()
  },
  methods: {
    async init() {
      Toast.loading({ message: '加载中...', forbidClick: true });
      //const { addressId, cartItemIds } = this.$route.query
      try{
        const data2  = await getSchedule(this.station_id);
        console.log(data2);
        this.platform_list = data2.data.platform_list;
        Toast.clear();
      } catch (error) {
          Toast.clear();
          Toast.fail(error)
          console.log(error);
          console.log("error ...")
      }
    },
    onButtonSetStationId() {
        console.log('onButtonSetStationId')
        setLocal('station_id', this.station_id);
    },
    onButtonGoHome() {
        this.$router.push({ path: '/' })
    }
  },
  components: {
    
  }
}
</script>

<style lang="less" scoped>
  .home {
    color: purple;
  }
</style>