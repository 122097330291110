// user.js
import axios from '../utils/axios' // 二次封装的 axios 

// 获取用户信息
//https://rt.data.gov.hk/v1/transport/mtr/lrt/getSchedule?station_id=260

export function getSchedule(stationId) {
  return axios.get('/openhk/v1/transport/mtr/lrt/getSchedule?station_id='+stationId);
}


